.content-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.2rem !important;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

@media screen and (max-width: 400px) {
  .content-container {
    padding: 0 0.6rem !important;
  }
}
