@import "./CryptoDATA-template/components/index";
@import "./CryptoDATA-template/components/containers";
@import "./CryptoDATA-template/base/helpers";
@import "./CryptoDATA-template/base/vars";

.content-container-col {
   margin-left: auto;
   margin-right: auto;
   width: 2000px;
   max-width: 100%;
   height: 100%;
}

.jumbotron {
   background: transparent !important;
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

   .card {
      border: none !important;
   }

   .row {
      border: none !important;
   }
}

.input-filter {
   border: none !important;
   border-bottom: 2px solid $color-background-orange !important;
   border-radius: 0 !important;

   &:focus-visible {
      outline: none !important;
   }
}

.css-yk16xz-control {
   border: none !important;
   border-radius: 0 !important;

   &:focus {
      border: none !important;
   }
}

.title {
   padding-top: 30px;
   border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.tooltip-style {
   width: 100px;
   background-color: $dark-grey;
   color: #fff;
   text-align: center;
   border-radius: 6px;
   padding: 5px 0;
   position: absolute;
   z-index: 1;
   bottom: 150%;
   margin-left: -75px;
}

.tooltip-style::after {
   content: "";
   position: absolute;
   top: 100%;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: $dark-grey transparent transparent transparent;
}

.custom-switch {
   z-index: 0 !important;
}

.pagination {
   z-index: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
   background-color: $color-background-orange !important;
}

// input focus color
.form-control:focus,
.css-1pahdxg-control:hover {
   border-color: $color-background-orange !important;
   box-shadow: none !important;
}

// input type number - remove arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type="number"] {
   -moz-appearance: textfield;
}

.page-item .page-link {
   padding: 0.5rem 1rem !important;
   border-color: $dark-color !important;
   background-color: $dark-grey !important;
   color: #fff !important;

   &:hover {
      background-color: $dark-color !important;
   }
}

.page-item.active .page-link {
   background-color: $color-background-orange !important;
   color: $color-black !important;
}

@media screen and (max-width: 768px) {
   .title {
      border-bottom: none;
      padding-top: 0;
      & > h2 {
         font-size: 20px;
      }
   }
}
